.reveal{
  .close-button{
    top: 7px;
  }
}

.button--idcat {
  background-color: var(--colors-gencat-red);
  border: 1px solid var(--colors-gencat-red);
  color: white;
}
