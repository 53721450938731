:root {
  --colors-gencat-red: #c00000;
  --colors-gencat-select: #efefef;
  --colors-gencat-gray-hover: #404040;
  --colors-gencat-gray-dark: #2c2930;
  --colors-gencat-black-footer: #020202;
  --colors-gencat-gray-footer: #d3d3d3;
  --colors-gencat-gray-medium: #828282;
  --colors-gencat-text-normal: #666666;
  --colors-gencat-text-grey: #333;
  --colors-card-default: #d9d9d9;
  --colors-card-info: #d9edf7;
  --colors-card-warning: #fcf8e3;
  --colors-card-danger: #f2dede;
  --colors-card-success: #dff0d8;
  --colors-card-primary: #f5f5f5;
}

@font-face {
  font-family: "Open Sans";
  src: url("fonts/OpenSans-Regular.ttf");
  src: url("fonts/OpenSans-Regular.ttf?#iefix") format("truetype"), url("fonts/OpenSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans Semibold";
  src: url("fonts/OpenSans-Semibold.ttf");
  src: url("fonts/OpenSans-Semibold.ttf?#iefix") format("truetype"), url("fonts/OpenSans-Semibold.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans Bold";
  src: url("fonts/OpenSans-Bold.ttf");
  src: url("fonts/OpenSans-Bold.ttf?#iefix") format("truetype"), url("fonts/OpenSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans Italic";
  src: url("fonts/OpenSans-Italic.ttf");
  src: url("fonts/OpenSans-Italic.ttf?#iefix") format("truetype"), url("fonts/OpenSans-Italic.ttf") format("truetype");
}
/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 *
 * We rename the basic bootstrap grid to use it in our Framework without collisions 
 */
.fwk-row {
  margin-left: -15px;
  margin-right: -15px;
}
.fwk-row::before, .fwk-row::after {
  display: table;
  content: " ";
}
.fwk-row::after {
  clear: both;
}

.fwk-col-xs-1, .fwk-col-sm-1, .fwk-col-md-1, .fwk-col-lg-1, .fwk-col-xs-2, .fwk-col-sm-2, .fwk-col-md-2, .fwk-col-lg-2, .fwk-col-xs-3, .fwk-col-sm-3, .fwk-col-md-3, .fwk-col-lg-3, .fwk-col-xs-4, .fwk-col-sm-4, .fwk-col-md-4, .fwk-col-lg-4, .fwk-col-xs-5, .fwk-col-sm-5, .fwk-col-md-5, .fwk-col-lg-5, .fwk-col-xs-6, .fwk-col-sm-6, .fwk-col-md-6, .fwk-col-lg-6, .fwk-col-xs-7, .fwk-col-sm-7, .fwk-col-md-7, .fwk-col-lg-7, .fwk-col-xs-8, .fwk-col-sm-8, .fwk-col-md-8, .fwk-col-lg-8, .fwk-col-xs-9, .fwk-col-sm-9, .fwk-col-md-9, .fwk-col-lg-9, .fwk-col-xs-10, .fwk-col-sm-10, .fwk-col-md-10, .fwk-col-lg-10, .fwk-col-xs-11, .fwk-col-sm-11, .fwk-col-md-11, .fwk-col-lg-11, .fwk-col-xs-12, .fwk-col-sm-12, .fwk-col-md-12, .fwk-col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.fwk-col-xs-1, .fwk-col-xs-2, .fwk-col-xs-3, .fwk-col-xs-4, .fwk-col-xs-5, .fwk-col-xs-6, .fwk-col-xs-7, .fwk-col-xs-8, .fwk-col-xs-9, .fwk-col-xs-10, .fwk-col-xs-11, .fwk-col-xs-12 {
  float: left;
}

.fwk-col-xs-12 {
  width: 100%;
}

.fwk-col-xs-11 {
  width: 91.66666667%;
}

.fwk-col-xs-10 {
  width: 83.33333333%;
}

.fwk-col-xs-9 {
  width: 75%;
}

.fwk-col-xs-8 {
  width: 66.66666667%;
}

.fwk-col-xs-7 {
  width: 58.33333333%;
}

.fwk-col-xs-6 {
  width: 50%;
}

.fwk-col-xs-5 {
  width: 41.66666667%;
}

.fwk-col-xs-4 {
  width: 33.33333333%;
}

.fwk-col-xs-3 {
  width: 25%;
}

.fwk-col-xs-2 {
  width: 16.66666667%;
}

.fwk-col-xs-1 {
  width: 8.33333333%;
}

.fwk-col-xs-pull-12 {
  right: 100%;
}

.fwk-col-xs-pull-11 {
  right: 91.66666667%;
}

.fwk-col-xs-pull-10 {
  right: 83.33333333%;
}

.fwk-col-xs-pull-9 {
  right: 75%;
}

.fwk-col-xs-pull-8 {
  right: 66.66666667%;
}

.fwk-col-xs-pull-7 {
  right: 58.33333333%;
}

.fwk-col-xs-pull-6 {
  right: 50%;
}

.fwk-col-xs-pull-5 {
  right: 41.66666667%;
}

.fwk-col-xs-pull-4 {
  right: 33.33333333%;
}

.fwk-col-xs-pull-3 {
  right: 25%;
}

.fwk-col-xs-pull-2 {
  right: 16.66666667%;
}

.fwk-col-xs-pull-1 {
  right: 8.33333333%;
}

.fwk-col-xs-pull-0 {
  right: auto;
}

.fwk-col-xs-push-12 {
  left: 100%;
}

.fwk-col-xs-push-11 {
  left: 91.66666667%;
}

.fwk-col-xs-push-10 {
  left: 83.33333333%;
}

.fwk-col-xs-push-9 {
  left: 75%;
}

.fwk-col-xs-push-8 {
  left: 66.66666667%;
}

.fwk-col-xs-push-7 {
  left: 58.33333333%;
}

.fwk-col-xs-push-6 {
  left: 50%;
}

.fwk-col-xs-push-5 {
  left: 41.66666667%;
}

.fwk-col-xs-push-4 {
  left: 33.33333333%;
}

.fwk-col-xs-push-3 {
  left: 25%;
}

.fwk-col-xs-push-2 {
  left: 16.66666667%;
}

.fwk-col-xs-push-1 {
  left: 8.33333333%;
}

.fwk-col-xs-push-0 {
  left: auto;
}

.fwk-col-xs-offset-12 {
  margin-left: 100%;
}

.fwk-col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.fwk-col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.fwk-col-xs-offset-9 {
  margin-left: 75%;
}

.fwk-col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.fwk-col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.fwk-col-xs-offset-6 {
  margin-left: 50%;
}

.fwk-col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.fwk-col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.fwk-col-xs-offset-3 {
  margin-left: 25%;
}

.fwk-col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.fwk-col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.fwk-col-xs-offset-0 {
  margin-left: 0%;
}

@media (min-width: 768px) {
  .fwk-col-sm-1, .fwk-col-sm-2, .fwk-col-sm-3, .fwk-col-sm-4, .fwk-col-sm-5, .fwk-col-sm-6, .fwk-col-sm-7, .fwk-col-sm-8, .fwk-col-sm-9, .fwk-col-sm-10, .fwk-col-sm-11, .fwk-col-sm-12 {
    float: left;
  }
  .fwk-col-sm-12 {
    width: 100%;
  }
  .fwk-col-sm-11 {
    width: 91.66666667%;
  }
  .fwk-col-sm-10 {
    width: 83.33333333%;
  }
  .fwk-col-sm-9 {
    width: 75%;
  }
  .fwk-col-sm-8 {
    width: 66.66666667%;
  }
  .fwk-col-sm-7 {
    width: 58.33333333%;
  }
  .fwk-col-sm-6 {
    width: 50%;
  }
  .fwk-col-sm-5 {
    width: 41.66666667%;
  }
  .fwk-col-sm-4 {
    width: 33.33333333%;
  }
  .fwk-col-sm-3 {
    width: 25%;
  }
  .fwk-col-sm-2 {
    width: 16.66666667%;
  }
  .fwk-col-sm-1 {
    width: 8.33333333%;
  }
  .fwk-col-sm-pull-12 {
    right: 100%;
  }
  .fwk-col-sm-pull-11 {
    right: 91.66666667%;
  }
  .fwk-col-sm-pull-10 {
    right: 83.33333333%;
  }
  .fwk-col-sm-pull-9 {
    right: 75%;
  }
  .fwk-col-sm-pull-8 {
    right: 66.66666667%;
  }
  .fwk-col-sm-pull-7 {
    right: 58.33333333%;
  }
  .fwk-col-sm-pull-6 {
    right: 50%;
  }
  .fwk-col-sm-pull-5 {
    right: 41.66666667%;
  }
  .fwk-col-sm-pull-4 {
    right: 33.33333333%;
  }
  .fwk-col-sm-pull-3 {
    right: 25%;
  }
  .fwk-col-sm-pull-2 {
    right: 16.66666667%;
  }
  .fwk-col-sm-pull-1 {
    right: 8.33333333%;
  }
  .fwk-col-sm-pull-0 {
    right: auto;
  }
  .fwk-col-sm-push-12 {
    left: 100%;
  }
  .fwk-col-sm-push-11 {
    left: 91.66666667%;
  }
  .fwk-col-sm-push-10 {
    left: 83.33333333%;
  }
  .fwk-col-sm-push-9 {
    left: 75%;
  }
  .fwk-col-sm-push-8 {
    left: 66.66666667%;
  }
  .fwk-col-sm-push-7 {
    left: 58.33333333%;
  }
  .fwk-col-sm-push-6 {
    left: 50%;
  }
  .fwk-col-sm-push-5 {
    left: 41.66666667%;
  }
  .fwk-col-sm-push-4 {
    left: 33.33333333%;
  }
  .fwk-col-sm-push-3 {
    left: 25%;
  }
  .fwk-col-sm-push-2 {
    left: 16.66666667%;
  }
  .fwk-col-sm-push-1 {
    left: 8.33333333%;
  }
  .fwk-col-sm-push-0 {
    left: auto;
  }
  .fwk-col-sm-offset-12 {
    margin-left: 100%;
  }
  .fwk-col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .fwk-col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .fwk-col-sm-offset-9 {
    margin-left: 75%;
  }
  .fwk-col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .fwk-col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .fwk-col-sm-offset-6 {
    margin-left: 50%;
  }
  .fwk-col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .fwk-col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .fwk-col-sm-offset-3 {
    margin-left: 25%;
  }
  .fwk-col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .fwk-col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .fwk-col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .fwk-col-md-1, .fwk-col-md-2, .fwk-col-md-3, .fwk-col-md-4, .fwk-col-md-5, .fwk-col-md-6, .fwk-col-md-7, .fwk-col-md-8, .fwk-col-md-9, .fwk-col-md-10, .fwk-col-md-11, .fwk-col-md-12 {
    float: left;
  }
  .fwk-col-md-12 {
    width: 100%;
  }
  .fwk-col-md-11 {
    width: 91.66666667%;
  }
  .fwk-col-md-10 {
    width: 83.33333333%;
  }
  .fwk-col-md-9 {
    width: 75%;
  }
  .fwk-col-md-8 {
    width: 66.66666667%;
  }
  .fwk-col-md-7 {
    width: 58.33333333%;
  }
  .fwk-col-md-6 {
    width: 50%;
  }
  .fwk-col-md-5 {
    width: 41.66666667%;
  }
  .fwk-col-md-4 {
    width: 33.33333333%;
  }
  .fwk-col-md-3 {
    width: 25%;
  }
  .fwk-col-md-2 {
    width: 16.66666667%;
  }
  .fwk-col-md-1 {
    width: 8.33333333%;
  }
  .fwk-col-md-pull-12 {
    right: 100%;
  }
  .fwk-col-md-pull-11 {
    right: 91.66666667%;
  }
  .fwk-col-md-pull-10 {
    right: 83.33333333%;
  }
  .fwk-col-md-pull-9 {
    right: 75%;
  }
  .fwk-col-md-pull-8 {
    right: 66.66666667%;
  }
  .fwk-col-md-pull-7 {
    right: 58.33333333%;
  }
  .fwk-col-md-pull-6 {
    right: 50%;
  }
  .fwk-col-md-pull-5 {
    right: 41.66666667%;
  }
  .fwk-col-md-pull-4 {
    right: 33.33333333%;
  }
  .fwk-col-md-pull-3 {
    right: 25%;
  }
  .fwk-col-md-pull-2 {
    right: 16.66666667%;
  }
  .fwk-col-md-pull-1 {
    right: 8.33333333%;
  }
  .fwk-col-md-pull-0 {
    right: auto;
  }
  .fwk-col-md-push-12 {
    left: 100%;
  }
  .fwk-col-md-push-11 {
    left: 91.66666667%;
  }
  .fwk-col-md-push-10 {
    left: 83.33333333%;
  }
  .fwk-col-md-push-9 {
    left: 75%;
  }
  .fwk-col-md-push-8 {
    left: 66.66666667%;
  }
  .fwk-col-md-push-7 {
    left: 58.33333333%;
  }
  .fwk-col-md-push-6 {
    left: 50%;
  }
  .fwk-col-md-push-5 {
    left: 41.66666667%;
  }
  .fwk-col-md-push-4 {
    left: 33.33333333%;
  }
  .fwk-col-md-push-3 {
    left: 25%;
  }
  .fwk-col-md-push-2 {
    left: 16.66666667%;
  }
  .fwk-col-md-push-1 {
    left: 8.33333333%;
  }
  .fwk-col-md-push-0 {
    left: auto;
  }
  .fwk-col-md-offset-12 {
    margin-left: 100%;
  }
  .fwk-col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .fwk-col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .fwk-col-md-offset-9 {
    margin-left: 75%;
  }
  .fwk-col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .fwk-col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .fwk-col-md-offset-6 {
    margin-left: 50%;
  }
  .fwk-col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .fwk-col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .fwk-col-md-offset-3 {
    margin-left: 25%;
  }
  .fwk-col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .fwk-col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .fwk-col-md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .fwk-col-lg-1, .fwk-col-lg-2, .fwk-col-lg-3, .fwk-col-lg-4, .fwk-col-lg-5, .fwk-col-lg-6, .fwk-col-lg-7, .fwk-col-lg-8, .fwk-col-lg-9, .fwk-col-lg-10, .fwk-col-lg-11, .fwk-col-lg-12 {
    float: left;
  }
  .fwk-col-lg-12 {
    width: 100%;
  }
  .fwk-col-lg-11 {
    width: 91.66666667%;
  }
  .fwk-col-lg-10 {
    width: 83.33333333%;
  }
  .fwk-col-lg-9 {
    width: 75%;
  }
  .fwk-col-lg-8 {
    width: 66.66666667%;
  }
  .fwk-col-lg-7 {
    width: 58.33333333%;
  }
  .fwk-col-lg-6 {
    width: 50%;
  }
  .fwk-col-lg-5 {
    width: 41.66666667%;
  }
  .fwk-col-lg-4 {
    width: 33.33333333%;
  }
  .fwk-col-lg-3 {
    width: 25%;
  }
  .fwk-col-lg-2 {
    width: 16.66666667%;
  }
  .fwk-col-lg-1 {
    width: 8.33333333%;
  }
  .fwk-col-lg-pull-12 {
    right: 100%;
  }
  .fwk-col-lg-pull-11 {
    right: 91.66666667%;
  }
  .fwk-col-lg-pull-10 {
    right: 83.33333333%;
  }
  .fwk-col-lg-pull-9 {
    right: 75%;
  }
  .fwk-col-lg-pull-8 {
    right: 66.66666667%;
  }
  .fwk-col-lg-pull-7 {
    right: 58.33333333%;
  }
  .fwk-col-lg-pull-6 {
    right: 50%;
  }
  .fwk-col-lg-pull-5 {
    right: 41.66666667%;
  }
  .fwk-col-lg-pull-4 {
    right: 33.33333333%;
  }
  .fwk-col-lg-pull-3 {
    right: 25%;
  }
  .fwk-col-lg-pull-2 {
    right: 16.66666667%;
  }
  .fwk-col-lg-pull-1 {
    right: 8.33333333%;
  }
  .fwk-col-lg-pull-0 {
    right: auto;
  }
  .fwk-col-lg-push-12 {
    left: 100%;
  }
  .fwk-col-lg-push-11 {
    left: 91.66666667%;
  }
  .fwk-col-lg-push-10 {
    left: 83.33333333%;
  }
  .fwk-col-lg-push-9 {
    left: 75%;
  }
  .fwk-col-lg-push-8 {
    left: 66.66666667%;
  }
  .fwk-col-lg-push-7 {
    left: 58.33333333%;
  }
  .fwk-col-lg-push-6 {
    left: 50%;
  }
  .fwk-col-lg-push-5 {
    left: 41.66666667%;
  }
  .fwk-col-lg-push-4 {
    left: 33.33333333%;
  }
  .fwk-col-lg-push-3 {
    left: 25%;
  }
  .fwk-col-lg-push-2 {
    left: 16.66666667%;
  }
  .fwk-col-lg-push-1 {
    left: 8.33333333%;
  }
  .fwk-col-lg-push-0 {
    left: auto;
  }
  .fwk-col-lg-offset-12 {
    margin-left: 100%;
  }
  .fwk-col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .fwk-col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .fwk-col-lg-offset-9 {
    margin-left: 75%;
  }
  .fwk-col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .fwk-col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .fwk-col-lg-offset-6 {
    margin-left: 50%;
  }
  .fwk-col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .fwk-col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .fwk-col-lg-offset-3 {
    margin-left: 25%;
  }
  .fwk-col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .fwk-col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .fwk-col-lg-offset-0 {
    margin-left: 0%;
  }
}
.fwk-pull-right {
  float: right !important;
}

.fwk-pull-left {
  float: left !important;
}

.fwk-hide {
  display: none !important;
}

.fwk-show {
  display: block !important;
}

* {
  font-family: "Open Sans" !important;
}

body {
  color: var(--colors-gencat-text-grey2);
  font-family: "Open Sans", "Source Sans Pro" !important;
}

.budget-summary p {
  color: black;
}

.alert {
  border-left-color: var(--colors-gencat-red) !important;
}
.alert svg {
  fill: var(--colors-gencat-red) !important;
}

.reveal .close-button {
  top: 7px;
}

.button--idcat {
  background-color: var(--colors-gencat-red);
  border: 1px solid var(--colors-gencat-red);
  color: white;
}

.footer-feder {
  color: var(--colors-gencat-text-grey2);
}
.footer-feder .main-footer__top {
  justify-content: space-between;
}
.footer-feder .logo {
  display: flex;
}
.footer-feder .logo img {
  max-width: 280px;
}
.footer-feder .logo .external-link-indicator {
  top: -8px !important;
  right: -250px !important;
}

.main-footer {
  background-color: var(--colors-gencat-gray-footer) !important;
}
.main-footer p,
.main-footer h2,
.main-footer a {
  color: var(--colors-gencat-black-footer);
}
.main-footer li,
.main-footer a {
  text-decoration-line: none !important;
  text-decoration: none !important;
  font-weight: normal !important;
}
.main-footer__language-container span {
  color: var(--colors-gencat-black-footer) !important;
}
.main-footer__language-container button {
  border-color: var(--colors-gencat-black-footer);
}
.main-footer__language-container svg {
  fill: var(--colors-gencat-black-footer) !important;
}

.mini-footer {
  background-color: var(--colors-gencat-gray-dark) !important;
}

.home .home__menu {
  padding: 0 !important;
  background-color: white;
}
.home .home__menu a {
  color: var(--colors-gencat-text-grey);
}
.home .home__menu-element {
  padding: 16px;
  border-right: 1px solid #ddd;
  font-size: 16px;
  line-height: 21px;
}
.home .home__menu-element:hover {
  background-color: white;
  box-shadow: 0px 4px 0px 0px var(--colors-gencat-red);
}
.home .home__menu-element--link {
  font-size: 16px;
  line-height: 21px;
}
.home .home__menu-element:last-child {
  border: none;
}
.home .home__section-content-banner__title {
  font-size: 3em;
}
.home #sub_hero h2 {
  color: var(--colors-gencat-text-normal) !important;
}
.home .home__section-title,
.home .home__section-participate-grid__title {
  color: var(--colors-gencat-text-normal) !important;
}
.home .content-block__title h2 {
  color: var(--colors-gencat-text-normal) !important;
}

.menu-bar__container {
  background-color: white !important;
}
.menu-bar__container a,
.menu-bar__container span {
  color: var(--colors-gencat-text-grey);
}
.menu-bar__container svg {
  fill: var(--colors-gencat-text-grey) !important;
}

.menu-bar__secondary-dropdown__menu {
  background-color: white !important;
  color: var(--colors-gencat-text-grey);
}

.menu-bar__actions:hover span {
  color: white !important;
}
.menu-bar__actions:hover svg {
  fill: white !important;
}

.hero__title {
  font-size: 2.5rem;
  line-height: 45px;
}
@media (min-width: 640px) {
  .hero__title {
    font-size: 4.5rem;
    line-height: 60px;
  }
}

.layout-2col__aside .title-decorator {
  color: var(--colors-gencat-text-grey) !important;
}

.layout-2col__main h2 {
  color: var(--colors-gencat-text-grey) !important;
}

#dropdown-menu-filters .filter span {
  white-space: wrap;
}

.gencat-navbar {
  background-color: var(--colors-gencat-gray-hover);
}
.gencat-navbar .custom-navbar {
  @apply flex justify-between w-full py-4 container;
  padding-left: 77px;
}
.gencat-navbar .custom-navbar img {
  height: 2rem;
}
.gencat-navbar .custom-navbar a {
  color: white;
  font-size: 20px;
}
.gencat-navbar .custom-navbar a:hover {
  color: white;
  text-decoration: underline;
}
.gencat-navbar .custom-navbar a span {
  display: none;
}
@media (max-width: 39.99em) {
  .gencat-navbar .language-choose {
    display: none !important;
  }
}

.main-bar__container {
  background-color: var(--colors-gencat-text-grey);
}
.main-bar__container .main-bar__links-desktop__item {
  color: white;
}
.main-bar__container .main-bar__links-desktop__item:hover {
  color: var(--colors-gencat-text-grey);
}
.main-bar__container .main-bar__links-desktop__item:hover svg {
  fill: var(--colors-gencat-text-grey);
}
.main-bar__container .main-bar__links-desktop__item svg {
  fill: white;
}
.main-bar__container .part-circle {
  display: inline;
  position: relative;
  height: 2em;
  width: 2em;
  float: left;
  margin-left: 0.5em;
  padding: 0.4em;
  background: var(--colors-gencat-text-normal);
  border-radius: 100%;
  color: white;
  font-weight: normal;
  font-size: 0.875em;
  text-align: center;
  line-height: 1.1em;
}
.main-bar__container .part-circle:hover {
  background-color: white;
  color: black;
}
.main-bar__container .main-bar__logo span {
  display: none;
}
.main-bar__container .main-bar__logo img {
  height: 2em;
  text-indent: -555em;
  margin: 0.5em 15px;
  cursor: pointer;
}
.main-bar__container .main-bar__links-mobile__trigger svg {
  color: white;
}
.main-bar__container .main-bar__avatar {
  border-color: white !important;
}
.main-bar__container .main-bar__avatar span {
  color: white;
}

#hits {
  width: 100%;
}

li.ais-Hits-item,
ul.ais-HierarchicalMenu-list {
  list-style-type: none;
}

span.ais-HierarchicalMenu-count:after {
  content: ")";
}

span.ais-HierarchicalMenu-count:before {
  content: " (";
}

span.ais-HierarchicalMenu-count {
  color: var(--colors-gencat-text-grey);
  font-size: smaller;
}

div.ais-HierarchicalMenu > ul {
  margin-left: 0;
}

div.ais-HierarchicalMenu a {
  text-decoration: none;
}

div.ais-HierarchicalMenu a:hover,
li.ais-HierarchicalMenu-item--selected > div > a {
  font-weight: bold;
}

ul.ais-HierarchicalMenu-list {
  margin-left: 0.8em;
}

.ais-HierarchicalMenu-label {
  color: var(--colors-gencat-red);
}

.card {
  padding: 1.5rem;
  margin-bottom: 30px;
}
.card-data {
  display: flex;
  justify-content: space-between;
}
.card-data__item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-data__item svg {
  margin-right: 4px;
}
.card .text-secondary {
  display: block;
  margin-bottom: 8px;
}
.card .text p {
  margin-bottom: 16px;
}

.card__highlight-container {
  display: block;
}

.mini-title {
  font-weight: 700;
  color: rgb(62, 76, 92, var(--tw-text-opacity));
  margin-bottom: 6px;
}

.filter-container .form-group {
  margin-bottom: 12px;
}

.ais-ClearRefinements {
  float: right;
  font-size: small;
  position: relative;
  top: -2em;
}

.ais-ClearRefinements {
  float: right;
  font-size: small;
  position: relative;
  top: -2em;
}

.ais-ClearRefinements-button {
  cursor: pointer;
}

.ais-ClearRefinements-button.ais-ClearRefinements-button--disabled {
  cursor: default;
}

.column.pagination {
  display: block;
  width: 100%;
  text-align: center;
}

#pagination {
  background: white;
  border-radius: 4px;
  border: 1px solid #f4f4f4;
  display: inline-block;
}

.ais-Pagination-item {
  display: inline-block;
  min-width: 40px;
  text-align: center;
}

.ais-Pagination-link {
  font-size: 1.3em;
  font-weight: bold;
}

.ais-Pagination-link:hover {
  text-decoration: none;
}

ul.ais-Pagination-list {
  margin: 0 0.5em;
}

p#stats {
  margin-left: 2em;
}

li.ais-Pagination-item.ais-Pagination-item--page.ais-Pagination-item--selected,
li.ais-Pagination-item.ais-Pagination-item--page.ais-Pagination-item--selected:hover {
  background: #e8e8e8;
  color: #3d393c;
}

.ais-Pagination li.ais-Pagination-item--nextPage a,
.ais-Pagination li.ais-Pagination-item--previousPage a,
.ais-Pagination li.ais-Pagination-item--firstPage a,
.ais-Pagination li.ais-Pagination-item--lastPage a,
.ais-Pagination li.ais-Pagination-item--nextPage span,
.ais-Pagination li.ais-Pagination-item--previousPage span,
.ais-Pagination li.ais-Pagination-item--firstPage span,
.ais-Pagination li.ais-Pagination-item--lastPage span {
  color: transparent;
  display: block;
  width: 35px;
  height: 35px;
  content: "";
  padding: 0 0.5em;
}

.ais-Pagination li.ais-Pagination-item--nextPage a {
  background: url("images/finder-next.png") no-repeat center center;
}

.ais-Pagination li.ais-Pagination-item--previousPage a {
  background: url("images/finder-previous.png") no-repeat center center;
}

.ais-Pagination li.ais-Pagination-item--firstPage a {
  background: url("images/finder-first.png") no-repeat center center;
}

.ais-Pagination li.ais-Pagination-item--lastPage a {
  background: url("images/finder-last.png") no-repeat center center;
}

.ais-Pagination li.ais-Pagination-item--nextPage span {
  background: url("images/finder-next_disabled.png") no-repeat center center;
}

.ais-Pagination li.ais-Pagination-item--previousPage span {
  background: url("images/finder-previous_disabled.png") no-repeat center center;
}

.ais-Pagination li.ais-Pagination-item--firstPage span {
  background: url("images/finder-first_disabled.png") no-repeat center center;
}

.ais-Pagination li.ais-Pagination-item--lastPage span {
  background: url("images/finder-last_disabled.png") no-repeat center center;
}

.most-voted-comments .comment-thread {
  min-width: 354px;
  margin-top: 8px;
  background-color: white;
  border-radius: 8px;
}
.most-voted-comments .editor-content {
  margin-bottom: 8px;
}
.most-voted-comments .comment__votes svg {
  margin-right: 2px;
}
.most-voted-comments .wrapper--inner {
  background: var(--colors-gencat-select);
  padding: 1rem;
}
.most-voted-comments .wrapper-home {
  background: white;
}
.most-voted-comments .no-results {
  font-size: 14px;
  color: var(--colors-gencat-text-normal);
}

.ql-editor-display ol li::before {
  width: auto;
}