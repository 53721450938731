.home {
  .home__menu {
    padding: 0 !important;
    background-color: white;
  
    a {
      color: var(--colors-gencat-text-grey);
    }
    
    &-element {
      padding: 16px;
      border-right: 1px solid #ddd;
      font-size: 16px;
      line-height: 21px;

      
      &:hover {
        background-color: white;
        box-shadow: 0px 4px 0px 0px var(--colors-gencat-red);
      }
    }

    &-element--link {
      font-size: 16px;
      line-height: 21px;
    }
    
    &-element:last-child {
      border: none;
    }
  }
  
  .home__section-content-banner__title {
    font-size: 3em;
  }

  #sub_hero {
    h2 {
      color: var(--colors-gencat-text-normal) !important;
    }
  }

  .home__section-title,
  .home__section-participate-grid__title {
    color: var(--colors-gencat-text-normal) !important;
  }

  .content-block__title {
    h2 {
      color: var(--colors-gencat-text-normal) !important;
    }
  }
}

.menu-bar__container {
  background-color: white !important;

  a,
  span{
    color: var(--colors-gencat-text-grey);
  }

  svg {
    fill: var(--colors-gencat-text-grey) !important;
  }
}

.menu-bar__secondary-dropdown__menu {
  background-color: white !important;
  color: var(--colors-gencat-text-grey);
}

.menu-bar__actions {
  &:hover {
    span {
      color: white !important;
    }

    svg {
      fill: white !important;
    }
  }
}

.hero__title {
  font-size: 2.5rem;
  line-height: 45px;
  
  @media (min-width: 640px) {
    font-size: 4.5rem;
    line-height: 60px;
  }
}
